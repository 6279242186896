import React from 'react'
import { Button } from 'semantic-ui-react'
import close from '../../assets/images/close.png'
import welcome1 from '../../assets/images/welcome-img1.jpg'
import welcome2 from '../../assets/images/welcome-img2.jpg'

export default function WelcomeCompoent({handleClose}) {
  return (
    <div className="welcome-modal">
      <div className="close-icon">
        <img src={close} alt="close" onClick={handleClose} />
      </div>
      <div className="welcome-info pt-4">
        <h3 className="mb-4">
          Welcome, {localStorage.getItem('first_name') || ''}
        </h3>
        <div className="welcome-img mb-4">
          <img src={welcome1} alt="welcome1" />
          <img src={welcome2} alt="welcome2" />
        </div>
        <h3>Account Review in Progress</h3>
        <p>
          Thanks for signing up to Limephone! We're excited to help you up and
          running. Your dedicated account manager will give you a call within
          our office hours <span>(Mon - Sat, 9.30 - 17.00 UK time)</span> to
          verify your account and get it set up to suit your needs perfectly.
          Once activated, you'll be able to use all the features, including
          calling, texting, and callbacks.
        </p>
        <div className="action-btn py-3">
          <Button className="btn-demo" onClick={handleClose}>
            <a href="https://limephone.io/onboarding/" target="_blank">
              Book a demo
            </a>
          </Button>
          <Button className="btn-ok">
            <a href="mailto:support@limephone.io">Contact support</a>
          </Button>
        </div>
      </div>
    </div>
  )
}
