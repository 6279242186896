import React, { Component } from 'react'
import icon from '../assets/images/settingIcon.png'
import InnerNavigation from '../common/InnerNavigation'
import Title from '../common/Title'
import Country from '../helpers/CountryList'
import CommonButtons from '../common/CommonButtons'
import CloseIcon from '@mui/icons-material/Close'

import { Popover, Button } from '@mui/material'



const titleContent = {
  type: 'image',
  titleOne: icon,
  titleTwo: 'Buy a Number'
}

const settingsData = [
  {
    mainSidebar: 'AddNumbers',
    innerTabs: ['Local', 'TollFree', 'Mobile']
  }
]

class AddNumber extends Component {
    state = {
    anchorEl: null,
    open:false

  }
  componentDidMount = () => {
    document.title = 'Add Number'
  }
     handlePopoverClose = () => {
        this.setState({open:false,anchorEl:null})
      }
     handlePopoverOpen = (event) => {
        this.setState({open:true,anchorEl:event?.currentTarget})
      }
      
  render() {
    return (
      <div
        className="fullwidth-container number-container"
        style={{ paddingRight: '90px' }}
      >
        <Title data={titleContent} />
        <div className="numbers-banner-container add-number add_number_inner">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#fff'
            }}
          >
            <div
              className="numbers-banner"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                padding: '50px 0'
              }}
            >
              <h1 className="banner-title">
                Find your perfect number
                {/* <div className='banner-info-text'>
              Set up local and toll-free numbers to increase contact rate with your prospects
            </div> */}
              </h1>
              <p style={{ fontSize: '18px' }}>
                Search for available numbers by country, area code, or features.
              </p>
            </div>
            <CommonButtons
              content="Can't find a number?"
              btnClass="btn-blue"
              onMouseEnter={this.handlePopoverOpen}
            />
          </div>

          <Popover
            id="mouse-over-popover"
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            // disableRestoreFocus
          >
            <div
              style={{ maxWidth: '500px', padding: '15px', width: '100%' }}
              onMouseLeave={this.handlePopoverClose}
            >
              <div className="d-flex justify-content-between">
                <h2>Apply for exclusive phone numbers</h2>
                <CloseIcon onClick={() => this.handlePopoverClose()} />
              </div>
              <p>
                Certain phone numbers in LimePhone's inventory aren't accessible
                via the Console or API because they're not generally available
                to customers. Review the "Exclusive Phone Numbers" inventory for
                more options.
              </p>
            <div>
              <CommonButtons
                onClick={() =>
                  window?.open('https://www.limephone.io/exclusivenumbers')
                }
                content="Apply for exclusive numbers →"
                btnClass="btn-blue"
              />
            </div>
            </div>
          </Popover>
          <InnerNavigation
            dataComponent={settingsData}
            activeTab={this.props.activeTab}
            activeComponent={this.props.activeComponent}
            dataTitle={titleContent}
            CountryList={Country}
          />
        </div>
      </div>
    )
  }
}

export default AddNumber
