// import rg4js from 'raygun4js'
import axios from 'axios'
import classnames from 'classnames'
import 'rc-color-picker/assets/index.css'
import 'rc-time-picker/assets/index.css'
import React, { Fragment, Suspense, useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
// import routes from './const/setting/settingRoutes'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'
import ListComp from './containers/Lists'
// import ListComp from './containers/Lists'
import 'semantic-ui-css/semantic.min.css'
import { Dimmer, Loader, Modal } from 'semantic-ui-react'
import userflow from 'userflow.js'
import QuickSetupMember from '../src/containers/QuickSetupMember'
import { GET_SUBSCRIPTION } from './actions/types'
import CacheBuster from './CacheBuster'
import Notification from './common/Notification'
import PlanExpired from './common/PlanExpired'
import SeparateDialer from './common/SeparateDailer'
import { AuthContext } from './components/authentication/auth'
import PrivateRoute from './components/authentication/PrivateRoute'
// import NoRouteMatch from './containers/404'
import Navbar from './components/navbar/Navbar'
import NotificationSide from './components/notification/NotificationSide'
import Installation from './components/settings/Installation'
//todo import common subscription
import VerifyEmail from './components/verifyemail/VerifyEmail'
import WelcomeCompoent from './components/welcomeModal'
import AddNumber from './containers/AddNumber'
import AdminLogin from './containers/AdminLogin'
import AdminVerification from './containers/AdminVerification'
import Analytics from './containers/Analytics'
import ManageAnalyticSetting from './containers/AnalyticSetting'
import AppsumoSignup from './containers/AppsumoSignup'
import CallDashboard from './containers/CallDashboard'
import Campaign from './containers/Campaign'
// import Billing from './containers/Billing'
import Contacts from './containers/Contacts'
import ConversationContent from './containers/ConversationContent'
import ConversationV2 from './containers/ConversationV2'
// import SignUp from './containers/SignUp'
import EmailOTP from './containers/EmailOTP'
import GetStartedLog from './containers/GetStarted'
import Home from './containers/Home'
import HubSpotRedirection from './containers/HubSpotRedirection'
import * as PusherPushNotifications from '@pusher/push-notifications-web'

import Inbound from './containers/Inbound'
import Inbox from './containers/Inbox'
import Leads from './containers/Leads'
import LeadsV2 from './containers/LeadsV2'
import Login from './containers/Login'
import Logout from './containers/Logout'
import ManageLeads from './containers/ManageLeads'
import Meeting from './containers/Meeting'
import Meets from './containers/Meets'
import MyLeads from './containers/MyLeadsNew'
import Numbers from './containers/Numbers'
import PasswordReset from './containers/PasswordReset'
import QuickSetup from './containers/QuickSetup'
import Register from './containers/Register'
import RequestLink from './containers/RequestLink'
import ResetPasswordToken from './containers/ResetPasswordToken'
import SetCampaign from './containers/SetCampaign'
import Settings from './containers/Settings'
import SignUpV2 from './containers/SignUpNew'
// import SharableLink from './components/settings/SharableLink'
import SlackRedirection from './containers/SlackRedirection'
import ACSuspended from './containers/Suspended'
// import Conversation from './containers/Conversation'
import Tasks from './containers/Tasks'
// import PasswordUpdate from './containers/PasswordUpdate'
import WidgetPreview from './containers/WidgetPreview'
import Widgets from './containers/Widgets'
import Dialer from './helpers/dialer/Dialer'
import './stylesheets/main.scss'
import WidgetNewPreview from './containers/WidgetNewPreview'
import './stylesheets/main.scss'
//todo import common subscription
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom'
import Landing from './containers/Landing'
import Clarity from './containers/Clarity'
import CardView from './components/cardView/CardView'
import SMSInfo from './components/sms/SMSInfo'
import Compliance from './components/compliance/Compliance'
import MobileScreen from './components/mobileScreen/MobileScreen'


// import SharableLink from './components/settings/SharableLink'
// Redux

// import routes from './const/setting/settingRoutes'

// import VerifyEmail from './components/verifyemail/VerifyEmail'
// import Installation from './components/settings/Installation'
// import SlackRedirection from './containers/SlackRedirection'
// import HubSpotRedirection from './containers/HubSpotRedirection'
// import ResetPasswordToken from './containers/ResetPasswordToken'
// import RequestLink from './containers/RequestLink'

// import Inbound from './containers/Inbound'
// import ConversationV2 from './containers/ConversationV2'
// import Inbox from './containers/Inbox'
// import LeadsV2 from './containers/LeadsV2'
// import WelcomeCompoent from './components/welcomeModal'
// import Campaign from './containers/Campaign'
// import Analytics from './containers/Analytics'
// import SeparateDialer from './common/SeparateDailer'

// const VerifyEmail = lazy(() => import('./components/verifyemail/VerifyEmail'))
// const Installation = lazy(() => import('./components/settings/Installation'))
// const SlackRedirection = lazy(() => import('./containers/SlackRedirection'))
// const HubSpotRedirection = lazy(() => import('./containers/HubSpotRedirection'))
// const ResetPasswordToken = lazy(() => import('./containers/ResetPasswordToken'))
// const RequestLink = lazy(() => import('./containers/RequestLink'))

require('dotenv').config()

const beamsClient = new PusherPushNotifications.Client({
  instanceId: 'e4f11824-a160-4377-b7ad-2d8a980f05e9',
});
// let temp = document.getElementsByClassName(
//   'intercom-messenger-frame-enter-done'
// )
const App = () => {
  const [openModal, setOpenModal] = useState(false)
  const [status, setStatus] = useState(null)
  // const subscription = useSelector(state => state.home?.subscription)
  const id = localStorage?.getItem('id')
  // useEffect (()=>{
  //   if (isMobile) {


  //     window.location.replace('/info')
  //   }
  // },[])
  const getInfo = async () => {
    if (window?.location?.pathname === '/home') {
      const adminStatus = localStorage.getItem('admin_verification_status')
      const flag = localStorage.getItem('cancel_modal')
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription`
      if (status) {
        if (
          flag !== 'true' &&
          flag !== true &&
          adminStatus?.toString() &&
          +adminStatus !== 1 &&
          status !== 'trial_expired'
        ) {
          setOpenModal(true)
        }
      } else {
        axios
          .get(url)
          .then(res => {
            if (res) {
              const statuss = res?.data?.data?.status
              setStatus(statuss)

              if (
                flag !== 'true' &&
                flag !== true &&
                adminStatus?.toString() &&
                +adminStatus !== 1 &&
                statuss !== 'trial_expired'
              ) {
                setOpenModal(true)
              }
            }
          })
          .catch(function (error) {
          })
      }
    }
  }

  useEffect(() => {

    if (id) {

      beamsClient.start().then(() => {

        beamsClient.addDeviceInterest(`call-received-${id}`)
        beamsClient.addDeviceInterest(`user-lead-assign-${id}`)
        beamsClient.addDeviceInterest(`new-mention-in-lead-${id}`)
        beamsClient.addDeviceInterest(`lead-activity-${id}`)
        beamsClient.addDeviceInterest(`lead-note-tagged-${id}`)
        beamsClient.addDeviceInterest(`user-missed-call-${id}`)
        beamsClient.addDeviceInterest(`user-meeting-booked-${id}`)
        beamsClient.addDeviceInterest(`task-assigned-${id}`)
        beamsClient.addDeviceInterest(`upcoming-meeting-${id}`)
        beamsClient.addDeviceInterest(`voicemail-received-${id}`)
        beamsClient.addDeviceInterest(`schedule-call-reminder-${id}`)
        beamsClient.addDeviceInterest(`schedule-call-rescheduled-${id}`)
      })
    }

    beamsClient.getDeviceInterests()
      .then(interests => {
        // console.log("interests", interests) // Will log something like ["a", "b", "c"]
      })
      .catch(e => console.error('Could not get device interests', e));


    beamsClient.getUserId()
      .then(userId => {
        console.log("userId", userId) // Will log the current user id
      })
      .catch(e => console.error('Could not get user id', e));



  }, [id])

  useEffect(() => {
    // getInfo()
  }, [window.location?.pathname])

  // useEffect(() => {
  //   if (window) {
  //     return window?.Intercom('update', {
  //       hide_default_launcher: true
  //     })
  //   }
  //   return window?.Intercom('onHide', function() {
  //     return window?.Intercom('update', {
  //       hide_default_launcher: true
  //     })
  //   })
  // }, [window])

  // rg4js('apiKey', '71pSno4MdPm3xJPkPP7fGA')
  // rg4js('enablePulse', true)
  // rg4js('enableCrashReporting', true)
  // The listener

  // if user logged in
  const apiToken = localStorage.getItem('access_token')


  if (apiToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    // userid
    const uName =
      localStorage.getItem('first_name') +
      ' ' +
      localStorage.getItem('last_name')
    const uFirstName = localStorage.getItem('first_name')
    const uEmail = localStorage.getItem('email')
    const uId = localStorage.getItem('id')

    // console.info({ uName })

    // rg4js('setUser', {
    //   identifier: uId,
    //   isAnonymous: false,
    //   email: uEmail,
    //   firstName: uFirstName,
    //   fullName: uName
    // })
  } else {
    delete axios.defaults.headers.common['Authorization']
  }

  // if (process.env.REACT_APP_BASE_APP_URL === 'https://app.limecall.com') {
  //   if (localStorage.getItem('id') !== null) {
  //     userflow.init('ct_fihcxcq5dnh27e26locdr5r33q')
  //     if (!userflow.isIdentified()) {
  //       let email = localStorage.getItem('email')
  //       let full_name =
  //         localStorage.getItem('first_name') +
  //         ' ' +
  //         localStorage.getItem('last_name')
  //       let plan = localStorage.getItem('plan_name')
  //       let userid = localStorage.getItem('id')
  //       let signed_up_at = localStorage.getItem('signed_up_at')

  //       userflow.identify(userid, {
  //         name: full_name,
  //         email: email,
  //         plan: plan,
  //         signed_up_at: signed_up_at
  //       })
  //     } else {
  //     }
  //   }
  // }

  // }

  const existingTokens = localStorage.getItem('access_token')
  const [isNotification, setIsNotification] = useState(false)
  const [activeTab, setActiveTab] = useState('Account')
  const [activeComponent, setActiveComponent] = useState('MySettings')
  const [activeNumberTab, setActiveNumberTab] = useState('Manage Number')
  const [activeNumberComponent, setActiveNumberComponent] = useState(
    'Manage Number'
  )
  const [activeAddNumberTab, setActiveAddNumberTab] = useState('Local')
  const [activeAddNumberComponent, setActiveAddNumberComponent] = useState(
    'AddNumbers'
  )
  const [activeSetCampaignTab, setActiveSetCampaignTab] = useState('Configure')
  const [activeSetCampaignComponent, setActiveSetCampaignComponent] = useState(
    'SetCampaign'
  )
  const [
    activeNotificationComponent,
    setActiveNotificationComponent
  ] = useState('notification')
  const [activeNotificationTab, setActiveNotificationTab] = useState(
    'notification'
  )
  const [token, setToken] = useState(
    localStorage.getItem('access_token') || null
  )

  const handleActive = (activeTabData, activeComponentData) => {
    setActiveTab(activeTabData)
    setActiveComponent(activeComponentData)
  }

  const handleNumberActive = (activeTabData, activeComponentData) => {
    setActiveTab(activeTabData)
    setActiveNumberComponent(activeComponentData)
  }

  const onclickCloseNotification = () => {
    setIsNotification(!isNotification)
  }

  const handleClose = value => {
    setOpenModal(false)
    localStorage.setItem('cancel_modal', true)
  }

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload()
        }

        return (
          <>
            <Modal
              onClose={() => handleClose()}
              onOpen={() => setOpenModal(true)}
              open={openModal}
            >
              <WelcomeCompoent handleClose={handleClose} />
            </Modal>

            <AuthContext.Provider value={{ token: existingTokens }}>
              <Router basename="/" onUpdate={window.test()}>
                <ScrollToTop /* onClick={this.testing} */>
                  <div className="App">
                    {isNotification ? (
                      <Notification actionEvent={onclickCloseNotification} />
                    ) : null}
                    <div
                      className={classnames('main-container', {
                        'notif-active': isNotification
                      })}
                    >
                      <Suspense
                        fallback={
                          <Dimmer active={true} style={{ position: 'fixed' }}>
                            <Loader />
                          </Dimmer>
                        }
                      >
                        <Switch>
                          <Route
                            exact
                            path={[
                              '/settings',
                              '/home',
                              '/analytics',
                              '/leads',
                              '/pipeline',
                              '/inbound',
                              '/manageleads',
                              '/lead',
                              '/leadsV2',
                              '/my-leads',
                              '/meets',
                              '/widgets',
                              '/calldashboard',
                              // '/callLog',
                              '/contacts',
                              '/campaign',
                              '/lists',
                              '/meetings',
                              '/numbers',
                              '/addNumbers',
                              '/setCampaign',
                              '/conversation',
                              '/inbox',
                              '/sms-info',
                              '/conversationContent',
                              '/billing',
                              '/redirect-slack',
                              '/get-started',
                              '/settings/kyc',
                              '/settings/business',
                              '/hubspot-callback',
                              '/settings/Personal',
                              '/settings/referrals',
                              '/settings/Personal/Notifications',
                              '/settings/Personal/call_forwarding',
                              // '/settings/Personal/my_page',
                              '/settings/my_page',
                              '/settings/quick_replies',
                              '/settings/audit_log',
                              '/settings/Personal/Availability',
                              '/settings/Personal/working_hours',
                              '/settings/Personal/Calendars',
                              '/settings/Personal/personal_link',
                              '/settings/Company',
                              '/settings/Company/Business_Hours',
                              '/settings/Company/Notifications',

                              '/settings/Platform/Calls',
                              '/settings/Platform/Schedules',
                              '/settings/Platform/Countries',
                              '/settings/Platform/auto_response',
                              '/settings/Platform/BlackList',
                              '/settings/Platform/caller_id',
                              '/settings/platform/lead_qualification',
                              '/settings/integration',
                              '/settings/teams',
                              '/settings/users',

                              '/settings/Billing/Subscription',
                              '/settings/Billing/Invoices',
                              '/settings/Billing/payments',
                              '/settings/Billing/Recentchargeslog',
                              '/settings/Billing/Usage',
                              '/settings/Billing/Billing_info',
                              '/settings/Billing/payment_methods',

                              '/settings/Personal/integrations',

                              '/settings/API',
                              '/settings/API/inbound_webhook',
                              '/settings/API/outbound_webhook',

                              '/settings/installation',
                              '/settings/installation/share_your_link',
                              '/settings/users_&_teams',
                              '/compliance'
                            ]}
                            render={() => {
                              return (
                                <Fragment>
                                  <Navbar handleActive={handleActive} />
                                  <Dialer />
                                  <PlanExpired />

                                  {/*///////////////////////////////////////
                                   start of setting path
                            ///////////////////////////////////*/}

                                  <PrivateRoute
                                    exact
                                    path="/settings"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Account"
                                        settingActiveComponent="Personal"
                                      />
                                    )}
                                    onEnter={window.test()}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="KYC"
                                        settingActiveComponent="KYC"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Business"
                                        settingActiveComponent="Business"
                                      />
                                    )}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Personal"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Account"
                                        settingActiveComponent="Personal"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Personal/Notifications"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Notifications"
                                        settingActiveComponent="Personal"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    onEnter={window.test}
                                    path="/get-started"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={Clarity}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Personal/call_forwarding"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Call Forwarding"
                                        settingActiveComponent="Personal"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Personal/Availability"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Availability"
                                        settingActiveComponent="Personal"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Personal/working_hours"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Working Hours"
                                        settingActiveComponent="Personal"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/kyc"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="KYC"
                                        settingActiveComponent="KYC"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/referrals"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Referrals"
                                        settingActiveComponent="Referrals"
                                      />
                                    )}
                                  />
                                  {/* <PrivateRoute
                                  roles={[
                                    'Admin',
                                    'MANAGER',
                                    'OPERATOR',
                                    'Operation Manager',
                                    'Team Member',
                                    'Team Leader',
                                    'Widget Manager',
                                    'Manager'
                                  ]}
                                  path="/settings/Personal/my_page"
                                  component={() => (
                                    <Settings
                                      settingActiveTab="My Page"
                                      settingActiveComponent="personal"
                                    />
                                  )}
                                /> */}
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/my_page"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="My Page"
                                        settingActiveComponent="My Page"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/quick_replies"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Quick Replies"
                                        settingActiveComponent="Quick Replies"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/audit_log"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Audit Log"
                                        settingActiveComponent="Audit Log"
                                      />
                                    )}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Personal/Calendars"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Calendars"
                                        settingActiveComponent="Personal"
                                      />
                                    )}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Personal/integrations"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Calendars"
                                        settingActiveComponent="Personal"
                                      />
                                    )}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Personal/personal_link"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Personal Link"
                                        settingActiveComponent="Personal"
                                      />
                                    )}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Company"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Company"
                                        settingActiveComponent="Company"
                                      />
                                    )}
                                  />
                                  {/* <PrivateRoute
                                  roles={[
                                    'Admin',
                                    'MANAGER',
                                    'Operation Manager',
                                    'Widget Manager',
                                    'Manager'
                                  ]}
                                  path="/settings/Company/business_hours"
                                  component={() => (
                                    <Settings
                                      settingActiveTab="Business Hours"
                                      settingActiveComponent="Company"
                                    />
                                  )}
                                /> */}
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Company/Notifications"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Notifications"
                                        settingActiveComponent="Company"
                                      />
                                    )}
                                  />
                                  <Route
                                    path="/compliance"
                                    component={Compliance}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Platform/Calls"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Calls"
                                        settingActiveComponent="Platform"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Platform/Schedules"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Schedules"
                                        settingActiveComponent="Platform"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Platform/Countries"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Countries"
                                        settingActiveComponent="Platform"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Platform/auto_response"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Auto Response"
                                        settingActiveComponent="Platform"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Platform/BlackList"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="BlackList"
                                        settingActiveComponent="Platform"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/Platform/caller_id"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Caller Id"
                                        settingActiveComponent="Platform"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/platform/lead_qualification"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Lead Qualification"
                                        settingActiveComponent="Platform"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/lead_qualification"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Lead Qualification"
                                        settingActiveComponent="Platform"
                                      />
                                    )}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path={'/settings/users_&_teams/teams'}
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Teams"
                                        settingActiveComponent={'Users&Teams'}
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path={'/settings/teams'}
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Teams"
                                        settingActiveComponent={'Users&Teams'}
                                      />
                                    )}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path={'/settings/users'}
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Users"
                                        settingActiveComponent={'Users&Teams'}
                                      />
                                    )}
                                  />

                                  <PrivateRoute
                                    roles={['Admin']}
                                    path="/settings/Billing/Subscription"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Subscription"
                                        settingActiveComponent="Billing"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={['Admin']}
                                    path="/settings/Billing/Invoices"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Invoices"
                                        settingActiveComponent="Billing"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={['Admin']}
                                    path="/settings/Billing/Payments"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Payments"
                                        settingActiveComponent="Billing"
                                      />
                                    )}
                                  />
                                  {/* <PrivateRoute
                            roles={["Admin"]} 
                            path="/settings/Billing/Recentchargeslog"
                            component={() => (
                              <Settings
                                settingActiveTab="Recentchargeslog"
                                settingActiveComponent="Billing"
                              />
                            )}
                          /> */}
                                  <PrivateRoute
                                    roles={['Admin']}
                                    path="/settings/Billing/Usage"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Usage"
                                        settingActiveComponent="Billing"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={['Admin']}
                                    path="/settings/Billing/Billing_info"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Account Details"
                                        settingActiveComponent="Billing"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={['Admin']}
                                    path="/settings/Billing/payment_methods"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Payment Methods"
                                        settingActiveComponent="Billing"
                                      />
                                    )}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/integration"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Calendar"
                                        settingActiveComponent="Calendar"
                                      />
                                    )}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/API"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="API"
                                        settingActiveComponent="API"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/API/Inbound_Webhook"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Inbound webhook"
                                        settingActiveComponent="API"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/API/Outbound_Webhook"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Outbound webhook"
                                        settingActiveComponent="API"
                                      />
                                    )}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/installation"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Installation"
                                        settingActiveComponent="Installation"
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/settings/installation/share_your_link"
                                    component={() => (
                                      <Settings
                                        settingActiveTab="Your live call page link"
                                        settingActiveComponent="Installation"
                                      />
                                    )}
                                  />

                                  {/*///////////////////////////////////////
                                   End of setting path
                            ///////////////////////////////////*/}

                                  <PrivateRoute
                                    onEnter={window.test}
                                    path="/home"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={Home}
                                  />
                                  <PrivateRoute
                                    onEnter={window.test}
                                    path="/analytics"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={Analytics}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/conversationContent"
                                    component={ConversationContent}
                                  />
                                  <PrivateRoute
                                    path="/contacts"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={Contacts}
                                  />

                                  <PrivateRoute
                                    path="/tasks"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={Tasks}
                                  />
                                  <PrivateRoute
                                    path="/meets"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={Meets}
                                  />
                                  <PrivateRoute
                                    nomatch
                                    onEnter={window.test}
                                    path="/inbound"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={Inbound}
                                  />
                                  <PrivateRoute
                                    onEnter={window.test}
                                    path="/leads"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={Leads}
                                  />
                                  <PrivateRoute
                                    onEnter={window.test}
                                    path="/leadsv2"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={LeadsV2}
                                  />
                                  <PrivateRoute
                                    onEnter={window.test}
                                    path="/manageleads"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={ManageLeads}
                                  />
                                  <PrivateRoute
                                    path="/my-leads"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={MyLeads}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/conversation"
                                    component={ConversationV2}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/inbox"
                                    component={Inbox}
                                  />

                                  <PrivateRoute
                                    path="/meetings"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={Meeting}
                                  />
                                  <PrivateRoute
                                    path="/campaign"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={Campaign}
                                  />
                                  <PrivateRoute
                                    path="/lists"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={ListComp}
                                  />
                                  <PrivateRoute
                                    path="/sms-info"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={SMSInfo}
                                  />
                                  <PrivateRoute
                                    path="/widgets"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={Widgets}
                                  />

                                  <PrivateRoute
                                    path="/calldashboard"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={CallDashboard}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/notification"
                                    component={() => (
                                      <NotificationSide
                                        activeComponent={
                                          activeNotificationComponent
                                        }
                                        activeTab={activeNotificationTab}
                                      />
                                    )}
                                  />

                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager',
                                      'Team Member'
                                    ]}
                                    path="/numbers"
                                    component={() => (
                                      <Numbers
                                        activeComponent={activeNumberComponent}
                                        activeTab={activeNumberTab}
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager',
                                      'Team Member'
                                    ]}
                                    path="/addNumbers"
                                    component={() => (
                                      <AddNumber
                                        activeComponent={
                                          activeAddNumberComponent
                                        }
                                        activeTab={activeAddNumberTab}
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager',
                                      
                                    ]}
                                    path="/setCampaign"
                                    component={() => (
                                      <SetCampaign
                                        activeComponent={
                                          activeSetCampaignComponent
                                        }
                                        activeTab={activeSetCampaignTab}
                                      />
                                    )}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'Operation Manager',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/redirect-slack"
                                    component={SlackRedirection}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/hubspot-callback"
                                    component={HubSpotRedirection}
                                  />
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/pipeline"
                                    component={CardView}
                                  />
                                </Fragment>
                              )
                            }}
                          />

                          <Route
                            exact
                            path={['/dialer']}
                            render={() => {
                              return (
                                <Fragment>
                                  <PrivateRoute
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    path="/dialer"
                                    component={() => <SeparateDialer />}
                                  />
                                </Fragment>
                              )
                            }}
                          />

                          <Route
                            path="/leads/:id"
                            render={() => {
                              return (
                                <Fragment>
                                  <Navbar handleActive={handleActive} />
                                  <Dialer />
                                  <PlanExpired />
                                  <PrivateRoute
                                    nomatch
                                    path="/leads/:id"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={CallDashboard}
                                  />
                                </Fragment>
                              )
                            }}
                          />

                          <Route
                            path="/numbers/:id"
                            render={() => {
                              return (
                                <Fragment>
                                  <Navbar handleActive={handleActive} />
                                  <Dialer />
                                  <PlanExpired />
                                  <PrivateRoute
                                    nomatch
                                    path="/numbers/:id"
                                    roles={[
                                      'Admin',
                                      'MANAGER',
                                      'OPERATOR',
                                      'Operation Manager',
                                      'Team Member',
                                      'Team Leader',
                                      'Widget Manager',
                                      'Manager'
                                    ]}
                                    component={SetCampaign}
                                  />
                                </Fragment>
                              )
                            }}
                          />

                          <Route path="/" exact component={QuickSetup} />
                          <Route
                            path="/request-link"
                            exact
                            component={RequestLink}
                          />
                          <Route
                            exact
                            path="/verify/:verification_key?"
                            component={VerifyEmail}
                          />
                          <Route
                            exact
                            path="/reset-password/:token?"
                            component={ResetPasswordToken}
                          />
                          <Route path="/login" component={Login} />
                          <Route path="/to-dialer" component={MobileScreen} />
                          <Route
                            path="/widget-preview/:apikey"
                            component={WidgetPreview}
                          />
                          <Route
                            path="/widget-new-preview/:apikey"
                            component={WidgetNewPreview}
                          />

                          <Route path="/logout" component={Logout} />
                          <Route path="/loginAs" component={AdminLogin} />
                          <Route path="/signup" component={SignUpV2} />
                          <Route path="/email-verify" component={EmailOTP} />
                          <Route
                            path="/admin-verification-status"
                            component={AdminVerification}
                          />
                          <Route
                            path="/account-suspended"
                            component={ACSuspended}
                          />
                          <Route path="/getstarted" component={GetStartedLog} />
                          <PrivateRoute
                            path="/QuickSetup"
                            roles={[
                              'Admin',
                              'MANAGER',
                              'OPERATOR',
                              'Operation Manager',
                              'Team Member',
                              'Team Leader',
                              'Widget Manager',
                              'Manager'
                            ]}
                            component={QuickSetup}
                          />

                          <PrivateRoute
                            path="/QuickSetupMember"
                            roles={[
                              'Admin',
                              'MANAGER',
                              'OPERATOR',
                              'Operation Manager',
                              'Team Member',
                              'Team Leader',
                              'Widget Manager',
                              'Manager'
                            ]}
                            component={QuickSetupMember}
                          />
                          <Route
                            path="/forgot-password"
                            component={PasswordReset}
                          />
                          <Route path="/register" component={Register} />
                          <Route
                            path="/appsumo-signup"
                            component={AppsumoSignup}
                          />

                          <Route path="/widgetLink" component={Installation} />

                          <Route
                            path="/user-google-analytics-properties"
                            render={props => (
                              <ManageAnalyticSetting {...props} />
                            )}
                          />
                          <Route path="/*" component={Home} to="/home">
                            <Redirect to="/home" />
                          </Route>
                        </Switch>
                      </Suspense>
                    </div>
                  </div>
                </ScrollToTop>
              </Router>
            </AuthContext.Provider>
          </>
        )
      }}
    </CacheBuster>
  )
}

export default App
