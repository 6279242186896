import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import CommonButtons from '../../common/CommonButtons'
import CommonSelectCountry from '../../common/CommonSelectCountry'
import CommonSelect2 from '../../common/CommonSelectRegion'
import CommonInput from '../../common/CommonInput'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip
} from '@mui/material'
import CountryList from '../../helpers/CountryList'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export default function YourPhone(props) {
  const [selectedNumber, setSelectedNumber] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [state, setState] = useState({
    selectedCountry: '',
    selectedRegion: '',
    regionList: [],
    haveRegion: false,
    haveAreaCode: false,
    countryListt: [],
    needAddress: false,
    city: '',
    loading: false,
    countryLoading: false,
    error: ''
  })
  const getRegion = async country => {
    if (state.selectedType === 'local') {
      if (country === 'United Kingdom') {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/uk-cities`
        Axios.get(url).then(res => {
          if (res.data.data) {
            const data = res?.data?.data

            const selectedReg = Object?.entries(data)?.map(([key, value]) => ({
              name: value,
              code: key
            }))
            if (selectedReg?.length && state.selectedType === 'local') {
              setState(prev => ({
                ...prev,
                haveRegion: true,
                regionList: [
                  {
                    name: 'Any',
                    code: 'Any'
                  },
                  ...selectedReg
                ]
              }))
            } else {
              setState(prev => ({
                ...prev,
                haveRegion: false,
                regionList: [],
                selectedRegion: ''
              }))
            }
          }
        })
      } else if (country === 'United States') {
        const regions = CountryList?.filter(
          obj => obj?.name === 'United States'
        )?.[0]?.regions
        setState(prev => ({
          ...prev,
          haveRegion: true,
          regionList: regions
        }))
      }
    } else {
      setState(prev => ({
        ...prev,
        haveRegion: false,
        regionList: []
      }))
    }
  }

  const hasAddess = country => {
    const con = state.countryListt?.filter(obj => obj?.name === country)?.[0]
    setState(prev => ({ ...prev, needAddress: con?.isAddress }))
  }

  const changeCountry = (p1, p2) => {
    const val = p2.value
    if (val == '') {
      return
    }
    setState(prev => ({ ...prev, selectedCountry: val }))

    setState(prev => ({ ...prev, selectedCountry: val }))

    getRegion(val)
    hasAddess(val)
    if (
      (val === 'United States' || val === 'United Kingdom') &&
      state.selectedType === 'local'
    ) {
      setState(prev => ({ ...prev, haveAreaCode: true }))
    } else {
      setState(prev => ({ ...prev, haveAreaCode: false, selectedAreaCode: '' }))
    }
  }

  const changeType = (p1, p2) => {
    const val = p2?.value
    setState(prev => ({ ...prev, selectedType: val }))
    if (val) {
      const quickSetupAc = localStorage?.getItem('quickSetupAc') 
        if(+quickSetupAc===2){
          getCountry(val)
     
      }else{
          const countries = [
            {
              name: 'United States',
              isAddress: true
            }
          ]
          setState(prev => ({
            ...prev,
            // selectedCountry: Object?.values(data)?.[0]?.country,
            countryListt: countries,
            countryError: countries?.length ? '' : 'Please select another type'
          }))
          changeCountry(null, { value: 'United States' })
      }

    }
  }

  useEffect(()=>{
    changeType(null , {value:'local'})
  },[])
  
  const getCountry = async type => {
    setState(prev => ({ ...prev, countryLoading: true, selectedCountry: '' }))
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/incoming-numbers-countries?number_type=${type}`
    await Axios.get(url)
      .then(res => {
        setState(prev => ({ ...prev, countryLoading: false }))

        if (res.data.data) {
          const data = res?.data?.data
          const countries = Object?.values(data)?.map(obj => ({
            name: obj?.country,
            isAddress: obj?.address
          }))
          setState(prev => ({
            ...prev,
            // selectedCountry: Object?.values(data)?.[0]?.country,
            countryListt: countries,
            countryError: countries?.length ? '' : 'Please select another type'
          }))
          changeCountry(null, { value: 'United States' })
        }
      })
      .catch(err => {
        setState(prev => ({
          ...prev,
          countryLoading: false,
          countryError: 'Please select another type'
        }))
      })
  }
  const changeAreaCode = e => {
    const val = e?.target?.value
    if (val?.length < 4) {
      setState(prev => ({ ...prev, selectedAreaCode: val }))
    }
  }

  const changeRegion = (p1, p2) => {
    const val = p2.value
    if (val == '') {
      return
    }
    setState(prev => ({ ...prev, selectedRegion: val }))
  }

  const getLocalNo = async () => {
    setState(prev => ({ ...prev, loading: true }))
    const plan = await localStorage.getItem('plan_name')

    const {
      city,
      fax,
      mms,
      sms,
      voice,
      selectedCountry,
      selectedRegion,
      selectedAreaCode
    } = state

    const params = `key=null&type=local&country=${selectedCountry}&region=${
      selectedCountry === 'United Kingdom'
        ? ''
        : selectedRegion && selectedRegion !== 'Any'
        ? selectedRegion
        : ''
    }&fax=${fax ? 'true' : 'false'}&mms=${mms ? 'true' : 'false'}&voice=${
      voice ? 'true' : 'false'
    }&sms=${sms ? 'true' : 'false'}&city=${
      selectedCountry === 'United Kingdom'
        ? selectedRegion && selectedRegion !== 'Any'
          ? selectedRegion
          : ''
        : city
    }&area_code=${selectedAreaCode ? selectedAreaCode : ''}`

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/get-available-phone-numbers?${params}`

    Axios.get(url)
      .then(res => {
        if (res.data.data) {
          let rows = []
          const amount = res.data.data.price
          const kyc = res.data.data.required_regulatory_bundle
            ? res.data.data.required_regulatory_bundle
            : false

          setState(prev => ({
            ...prev,
            phoneNumbers: res.data.data.phoneNumbers,
            noRecord: true,
            loading: false
          }))
          if (!res.data.data.phoneNumbers?.length) {
            setState(prev => ({
              ...prev,
              error:
                'Sorry, we currently have no stock of numbers . Please try a different region or area code'
            }))
          }
        }
      })
      .catch(error => {
        setState(prev => ({
          ...prev,
          loading: false,
          error:
            'Sorry, we currently have no stock of numbers . Please try a different region or area code'
        }))
      })
  }

  useEffect(() => {
    if (activeIndex === 0) {
      setState(prev => ({ ...prev, phoneNumbers: [], error: '' }))
      setSelectedNumber(null)
    }
  }, [activeIndex])
   const handleSubmit = async e => {
     e.preventDefault() 
       const data = {
       target_country: state.selectedNumber
         // domain: field?.domain
       }

       const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/onboarding-info`
       Axios
         .post(url, data)
         .then(res => {
           if (res?.data?.message == 'Successfully') {
           }
         })
         .catch(error => {
         
         })
   }
    const quickSetupAc = localStorage?.getItem('quickSetupAc') 
  return (
    <>
      <div className="assigned-screen">
        <h2>Pick your Phone number</h2>
        <div
          className="assigned-inner"
          style={{
            margin: '0 0 auto',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginBottom: '15px'
          }}
        >
          {activeIndex === 0 ? (
            <>
              <div className="mt-0" style={{ flex: 1 }}>
                <h3>Select Type</h3>
                <CommonSelect2
                  style={{ width: '45%', margin: 'auto', marginTop: '10px' }}
                  onChange={changeType}
                  name="config"
                  defaultValue={state.selectedType || 'local'}
                  className="popup-font-select"
                  placeholder="Select Type"
                  options={[
                    { name: 'Local', code: 'local' },
                    { name: 'Toll Free', code: 'tollFree' }
                    // { name: 'Mobile', code: 'mobile' }
                  ]}
                />
                <div className="mt-5">
                  {state.countryLoading ? (
                    <CircularProgress color="success" />
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          gap: 2,
                          margin: 'auto',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <h3>Select Country</h3>{' '}
                        <Tooltip
                          title={
                            +quickSetupAc === 2
                              ? 'Select the country where you would like a new number. if you do not see the country that you want a number for, please contact us.'
                              : 'You will be able to subscribe additional numbers from 40+ countries while upgrading to paid plan.'
                          }
                        >
                          <InfoOutlinedIcon />
                        </Tooltip>{' '}
                      </div>
                      <CommonSelectCountry
                        style={{
                          width: '45%',
                          margin: 'auto',
                          marginTop: '10px'
                        }}
                        onChange={changeCountry}
                        name="config"
                        defaultValue={state.selectedCountry || 'United States'}
                        className="popup-font-select"
                        placeholder="Select Country"
                        options={state.countryListt}
                      />
                    </>
                  )}
                </div>
                {state.countryError ? <p>{state.countryError} </p> : null}
              </div>
              <div
                className="d-flex justify-content-between w-100 mt-3"
                style={{ marginBottom: '35px' }}
              >
                <span onClick={props?.prev}>
                  <KeyboardBackspaceIcon /> Back
                </span>
                {state.selectedCountry ? (
                  <CommonButtons
                    type="button"
                    content="Next"
                    background="blue"
                    onClick={e => {
                      setActiveIndex(
                        (state?.haveRegion || state?.haveAreaCode) &&
                          activeIndex === 0
                          ? 1
                          : 2
                      )
                      !(state?.haveRegion || state?.haveAreaCode) &&
                        getLocalNo()
                    }}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div className="mt-0" style={{ flex: 1 }}>
                {state.haveRegion && (
                  <>
                    <h3>Which Region?</h3>
                    <CommonSelect2
                      style={{ width: '45%', margin: '0 auto' }}
                      onChange={changeRegion}
                      name="config"
                      className="popup-font-select"
                      placeholder="Select Region"
                      options={state.regionList}
                      disabled={state.haveRegion == false ? true : false}
                    />
                  </>
                )}
                {state.haveAreaCode && (
                  <div style={{ width: '45%', margin: '0 auto' }}>
                    <CommonInput
                      onChange={changeAreaCode}
                      placeholder="Enter Areacode"
                      name="areaCode"
                      type="number"
                      maxLength={3}
                      value={state.selectedAreaCode}
                      style={{
                        width: '100%',
                        margin: '0 auto',
                        marginTop: '10px'
                      }}
                    />
                  </div>
                )}
                {(state.haveRegion || state.haveAreaCode) && (
                  <CommonButtons
                    type="button"
                    content="Search Number"
                    background="blue"
                    onClick={() => getLocalNo()}
                    btnClass="ml-4 mt-3"
                  />
                )}
                <div className="mt-3">
                  {state.loading ? (
                    <CircularProgress color="success" />
                  ) : (
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        style={{
                          width: '80%',
                          padding: '10px',
                          margin: 'auto',
                          backgroundColor: 'cee3ff'
                        }}
                      >
                        {state.phoneNumbers?.length ? (
                          state.phoneNumbers?.map(val => (
                            <Grid
                              item
                              xs={12}
                              md={4}
                              gap={3}
                              style={{
                                padding: '10px',
                                backgroundColor: '#fff'
                              }}
                            >
                              <FormControlLabel
                                value={val}
                                control={
                                  <Radio
                                    onChange={e =>
                                      setSelectedNumber(e?.target?.value)
                                    }
                                  />
                                }
                                label={val}
                                onChange={(e, value) =>
                                  console.log(
                                    'e,value',
                                    e?.terget?.value,
                                    value
                                  )
                                }
                              />
                            </Grid>
                          ))
                        ) : (
                          <p style={{ margin: '0 auto' }}>{state?.error}</p>
                        )}
                      </Grid>
                    </RadioGroup>
                  )}
                </div>
              </div>
              <div
                className="d-flex justify-content-between w-100 mt-3 "
                style={{ marginBottom: '35px' }}
              >
                <span onClick={() => setActiveIndex(0)}>
                  <KeyboardBackspaceIcon /> Back
                </span>
                {selectedNumber && (
                  <CommonButtons
                    type="button"
                    content="Next"
                    background="blue"
                    onClick={e => {
                      handleSubmit(e)
                      props.next()
                    }}
                    // loading={loading}
                  />
                )}
              </div>
            </>
          )}

          {/* <Button primary>Continue</Button> */}
        </div>
        {+quickSetupAc !== 2 ? (
          <p className="mb-3">
            You'll get a US number to start your trial – easy peasy! If you need
            one from another country, please contact our support team and we're
            happy to help. 😊
          </p>
        ) : null}

        {activeIndex === 0 &&
        state.needAddress &&
        state.selectedCountry &&
        state.selectedCountry !== 'United States' ? (
          <p className="mb-3">
            {' '}
            Regulatory Requirement: This country requires a local address proof
            in {state.selectedCountry}
          </p>
        ) : (
          ''
        )}
        {/* <CommonButtons
          content="Back"
          background="grey"
          style={{ width: '100px' }}
          btnClass="btn-modal-style"
          onClick={props.prev}
        /> */}
      </div>
    </>
  )
}
