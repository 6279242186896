import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from './auth'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
let role_name = 'ADMIN'

function PrivateRoute({ component: Component, ...rest }) {
  const token = useAuth()
  const r = rest.roles
const navigate = useHistory()
  let existingTokens = localStorage.getItem('access_token')
  if (!rest.nomatch) {
    if (window?.location?.pathname?.toLowerCase() != rest.path.toLowerCase())
      return null
  }
  // check email verification status

  const emailStatus = localStorage.getItem('email_verification_status')
  //const adminVerify = localStorage.getItem("admin_verification_status")

  // if(!parseInt(emailStatus)){
  //   return(
  //     <Route
  //     {...rest}
  //     render={props =>
  //        <Redirect to="/email-verify" />
  //     }
  //   />
  //   )
  // }

  // if(!parseInt(adminVerify)){
  //   return(
  //     <Route
  //     {...rest}
  //     render={props =>
  //        <Redirect to="/admin-verification-status" />
  //     }
  //   />
  //   )
  // }

  if (!existingTokens) {
    if (window.location.pathname === '/dialer') {
      sessionStorage.setItem('isDialer', 1)
    }
    return <Route {...rest} render={props => <Redirect to="/logout" />} />
  }

  let role_name = localStorage.getItem('role_name')
  var b = !r ? false : r.includes(role_name ? role_name : 'OPERATOR')

  if (b) {
    return (
      <Route
        {...rest}
        render={props =>
          existingTokens ? (
            <Component {...props} />
          ) : isMobile ? (
            navigate.push('/to-dialer')
          ) : (
            <Redirect to="/home" />
          )
        }
      />
    )
  } else {
    return isMobile ? (
      navigate.push('/to-dialer')
    ) : (
      <Route {...rest} render={props => <Redirect to="/home" />} />
    )
  }
}

export default PrivateRoute
