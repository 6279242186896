import axios from 'axios'
import React from 'react'
import goLiveRocket from '../../assets/images/goliverocket.svg'
// import welcomeCall from '../../assets/images/welcome-call.png'
import dashboardMobile from '../../assets/images/dashboard-mobile.png'
// import welcomedash from '../../assets/images/welcome-dashboard.png'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import { useHistory } from 'react-router-dom'

const GoLive = props => {
  const history = useHistory()
  const skipOnboarding = async () => {
    localStorage.setItem('quick_setup', props?.redirectURL ? 8 : 7)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/skip-onboarding`
    axios
      .post(url, {})
      .then(res => {
        CommonNotify('Successfully updated', 'success')
        if (props?.redirectURL) {
          window.location.assign(props?.redirectURL)
        } else {
          window.location.assign('/conversation')
        }
      })
      .catch(err => { })
  }

  const goToTeam = () => {
    window.location.href = '/settings/team/manage_teammates'
  }

  const redirectToMobile = () =>{
    window.location.href = 'https://dialer.limephone.io' 
    history.go(1)
  }

  
  
const quickSetupAc = localStorage?.getItem('quickSetupAc')
  return (
    <div className="golive">
      {quickSetupAc === '2' ? (
        <div className="golive-content py-4">
          <p className="golive-title">You are good to go</p>
          <p className="golive-subtitle">It's time to grow with LimePhone</p>
          <div className="welcome-go-live">
            {/* <img src={welcomeCall} alt="welcomeCall" />
          <img src={welcomedash} alt="welcomedash" /> */}
            {/* <img src={dashboardMobile} alt="welcomedash" /> */}
          </div>
          <p className="golive-desc">
            To keep our community safe, we're reviewing your account. This
            usually takes a few hours. We'll email you as soon as it's activated
          </p>
          <div className="text-center w-100">
            <div
              className="quicksetup-footer footer-button mt-auto w-100"
              style={{ justifyContent: 'space-between' }}
            >
              <CommonButtons
                content="Continue to dashboard"
                background="blue"
                btnClass="btn-modal-styl p-3"
                onClick={() => skipOnboarding()}
              />
            </div>
            {/* <CommonButtons
            content="Continue to Web Dialer"
            background="blue"
            btnClass="btn-modal-style p-3"
            onClick={() => redirectToMobile()}
          /> */}
            {/* <CommonButtons
            content="Go to Dashboard"
            background="blue"
            btnClass="btn-modal-style"
            onClick={() => skipOnboarding()}
          /> */}
            {/* <CommonButtons
            onClick={() =>
              window.open(
                'https://play.google.com/store/apps/details?id=com.limecall&hl=en&gl=US&pli=1'
              )
            }
            content="Download Android App"
            background="alt-blue"
            btnClass="btn-modal-style p-3"
          /> */}
          </div>
          {/* <p className="golive-anchor">
          Alternatively,{' '}
          <a href="/settings/users_&_teams/users">Invite teammates.</a>
        </p> */}
        </div>
      ) : (
        <div
          className="golive-content py-4"
          style={{ width: '80%', margin: 'auto', alignItems: 'start' }}
        >
          <p className="golive-title">
            Your account is currently being reviewed
          </p>
          <p className="golive-subtitle">
            It may take upto 24 hours for your number to be activated as we
            review all new trials to avoid abuse. Please check your email for
            activation confirmation
          </p>
{/*          
          <ul>
            <li>
              {' '}
              No outgoing SMS: You can only receive SMS messages during your
              trial.
            </li>
            <li>
              {' '}
              Local calls to USA, UK, and Canada: Make upto 25 calls to these
              countries.
            </li>
            <li>
              {' '}
              Random phone number: You'll be assigned a temporary phone number
              during your trial. You can choose a permanent number when you
              upgrade to a paid plan.
            </li>
          </ul> */}
          <div className="text-center w-100">
            <div
              className="quicksetup-footer footer-button mt-auto w-100"
              style={{ justifyContent: 'space-between' }}
            >
              <CommonButtons
                content="Continue to dashboard"
                background="blue"
                btnClass="btn-modal-styl p-3"
                onClick={() => skipOnboarding()}
              />{' '}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default GoLive
