import React, { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-input-2'
import { useGetContactsQuery, useGetUserIdQuery } from '../features/api/apiSlice'
import {
  Button,
  Dropdown,
  Header,
  Modal,
  Input,
  TextArea,
  Table,
  FormGroup
} from 'semantic-ui-react'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { sendMessageUser } from '../config/leadAPI'
import CommonButtons from './CommonButtons'
import Axios from 'axios'
import { CommonNotify } from './CommonNotify'
import SavedRepliesModal from './SavedRepliesModal'
import moment from 'moment'
import { Preview } from '@mui/icons-material'
import { Col, Row } from 'react-bootstrap'

const SendMessageHeader = ({ open, isModalClose }) => {
  const user = useGetUserIdQuery()
  const [templateList, setTemplateList] = useState([])
  const [sendMsgData, setSendMsgData] = useState({
    from: null,
    message: '',
    to: ''
  })
  const [dropDownData, setDropdownData] = useState([])
  const [loading, setLoading] = useState(false)
  const [openReplayModal, setOpenReplayModal] = useState(false)
  const [openReplayScheduleModal, setOpenReplayScheduleModal] = useState(false)
  const [scheduleInfo, setScheduleInfo] = useState(null)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [messageData, setMessageData] = useState(false)
  const [messageTemplate, setMessageTemplate] = useState(null)
   const resContacts = useGetContactsQuery({ date: 'all', type: 'all' })
   const [name, setName] = useState('')
  useEffect(() => {
    getLeadTemplate()
  }, [])

  useEffect(() => {
    if (user.isSuccess) {
      setUserId(user)
    }
  }, [user])
  useEffect(() => {

  setName(
    resContacts?.data?.data?.filter(
      obj => obj?.phone_number === sendMsgData?.to
    )?.[0]?.name
  )
  }, [sendMsgData?.to])
  

  const getLeadTemplate = async () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-responses?target=Lead`
    await Axios.get(url)
      .then(res => {
        setTemplateList(res?.data?.data || [])
      })
      .catch(err => {
        setLoading(false)
        CommonNotify(
          err?.response?.data?.errors[0] || 'Somethign went to wrong!'
        )
      })
  }

 const setUserId = res => {
   const data = []
   const response = res.data.data?.[0]
   const data1 = response?.map((number, index) => {
     if (index !== response?.length - 1) {
       data.push({
         key: number,
         value: number,
         text: `LimeCall Number (${number})`
       })
     }
     return null
   })
   setDropdownData(data)
 }

  const handleSave = value => {
    setSendMsgData({
      ...sendMsgData,
      message: value?.description || ''
    })
  }

  const timeValue = value => {
    setScheduleInfo(value)
  }

  const handleTemplateChange = (e, result) => {
    setMessageTemplate(result?.value)
    const templateMessage =
      templateList.find(({ id }) => id === result?.value)?.description || ''
   setSendMsgData({
     ...sendMsgData,
     message: templateMessage || ''
   })
  }
  return (
    <>
      <Modal
        className="send-message-modal"
        onClose={isModalClose}
        closeIcon
        open={open}
        size="tiny"
      >
        {openReplayModal ? (
          <SavedRepliesModal
            {...{
              handleSave,
              templateList,
              getLeadTemplate,
              timeValue,
              scheduleInfo
            }}
            setOpenReplayModal={() => setOpenReplayModal(false)}
          />
        ) : (
          ''
        )}
        {openReplayScheduleModal ? (
          <SavedRepliesModal
            {...{
              handleSave,
              templateList,
              getLeadTemplate,
              timeValue,
              scheduleInfo
            }}
            schedule={true}
            setOpenReplayModal={() => setOpenReplayScheduleModal(false)}
          />
        ) : (
          ''
        )}
        <Header content="Send New Textw Message" />
        <Modal.Content style={{ padding: '20px' }}>
          <div className="send-message__dropdown">
            <label>From *</label>
            <Dropdown
              name="from"
              placeholder="Select Number"
              selection
              options={dropDownData}
              onChange={(e, data) =>
                setSendMsgData({ ...sendMsgData, from: data.value })
              }
            />
          </div>
          <div className="send-message__input">
            <div className="d-flex align-items-center mb-2">
              <label className="mb-0">To {name}</label>
              {/* <Dropdown
              placeholder="Select Templates"
              selection
              clearable
              className="sms-template-dropdown"
              options={templateList?.map((option, index) => ({
                key: index + 1,
                text: option?.title,
                value: option?.description
              }))}
              onChange={(e, data) => {
                setSendMsgData({ ...sendMsgData, message: data?.value })
              }}
            /> */}
            </div>

            <PhoneInput
              international
              className="text-color"
              country={'us'}
              onlyCountries={['us']}
              value={sendMsgData?.to}
              onChange={value => setSendMsgData({ ...sendMsgData, to: value })}
            />
          </div>
          <div style={{width: '100%' ,marginTop:'15px'}}>
            <label>Template:</label>

            <Row className="align-items-center">
              <Col xl={12} md={12}>
                <FormGroup>
                  <Dropdown
                    placeholder={'Select template...'}
                    fluid
                    search
                    selection
                    name="messageTemplate"
                    options={templateList?.map(({ title, id }, index) => ({
                      text: title,
                      key: index + 1,
                      value: id
                    }))}
                    value={messageTemplate}
                    onChange={handleTemplateChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="send-message__textarea">
            <label>Message * </label>
            <TextArea
              name="message"
              value={sendMsgData.message}
              onChange={e =>
                setSendMsgData({ ...sendMsgData, message: e.target.value })
              }
              rows={4}
              className={`${
                sendMsgData?.message?.length > 160 ? 'red-text' : ''
              }`}
              title="Message *"
            />
            <div className="d-flex">
              <BookmarkIcon onClick={() => setOpenReplayModal(true)} />
              <ScheduleIcon onClick={() => setOpenReplayScheduleModal(true)} />
            </div>
            <br />
            <p>Character Count {sendMsgData?.message?.length || 0} </p>
            {scheduleInfo ? (
              <p>
                Schdule Date & Time :{' '}
                {moment(scheduleInfo).format('MM-DD-YYYY, h:mm A')}{' '}
              </p>
            ) : (
              ''
            )}
          </div>

          {!dropDownData?.length ? (
            <p className="mt-2 send-message__btn">
              Please add and verify a caller id to send a message.{' '}
              <CommonButtons
                content="Add caller Id"
                btnClass="send-message__btn-blue"
                background="#007bff"
                style={{ color: 'white' }}
                // onClick={e => sendMessageUser(sendMsgData, isModalClose)}

                onClick={e =>
                  window.location.replace('/settings/platform/caller_id')
                }
              />
            </p>
          ) : null}
        </Modal.Content>
        <Modal.Actions className="send-message__btn">
          <Button onClick={isModalClose} className="send-message__btn-grey">
            close
          </Button>

          <CommonButtons
            disabled={!dropDownData?.length}
            content="send later"
            btnClass="send-message__btn-blue"
            background="#007bff"
            style={{ color: 'white' }}
            onClick={e => {
              if (scheduleInfo) {
                sendMessageUser(sendMsgData, isModalClose, scheduleInfo)
              } else {
                setOpenReplayScheduleModal(true)
              }
            }}
          />
          <CommonButtons
            disabled={!dropDownData?.length}
            content="send"
            btnClass="send-message__btn-blue"
            background="#007bff"
            style={{ color: 'white' }}
            // onClick={e => sendMessageUser(sendMsgData, isModalClose)}

            onClick={e =>
              sendMessageUser(sendMsgData, data => {
                setPreviewOpen(!previewOpen)
                setMessageData(data)
                isModalClose()
              })
            }
          />
        </Modal.Actions>
      </Modal>
      <Modal
        className="send-message-modal"
        onClose={isModalClose}
        closeIcon
        open={previewOpen}
        size="tiny"
      >
        <Header content="Sent Message Details" />
        <Modal.Content style={{ padding: '30px' }}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: '33%' }}></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell className="header-left">Message ID: </Table.Cell>
                <Table.Cell>{messageData?.id}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="header-left">Send Time: </Table.Cell>
                <Table.Cell>{messageData?.send_time}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="header-left">From: </Table.Cell>
                <Table.Cell>{messageData?.from}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="header-left">To: </Table.Cell>
                <Table.Cell>{messageData?.to}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="header-left">
                  Destination Country:{' '}
                </Table.Cell>
                <Table.Cell>{messageData?.destination_country}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="header-left">
                  Message content:{' '}
                </Table.Cell>
                <Table.Cell>{messageData?.content}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="header-left">Source: </Table.Cell>
                <Table.Cell>{messageData?.source}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="header-left">
                  Total Characters:{' '}
                </Table.Cell>
                <Table.Cell>{messageData?.total_characters}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="header-left">Message Parts: </Table.Cell>
                <Table.Cell>{messageData?.parts}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="header-left">Encoding: </Table.Cell>
                <Table.Cell>{messageData?.encoding_type}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="header-left">Status: </Table.Cell>
                <Table.Cell>{messageData?.status}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions className="send-message__btn">
          <Button
            onClick={() => setPreviewOpen(!previewOpen)}
            className="send-message__btn-grey"
          >
            close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}
export default SendMessageHeader
