import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip';


import { Modal } from 'semantic-ui-react'
import { useHistory, Redirect } from 'react-router-dom'

import avatar from '../assets/images/Dashboard-27.png'
import avatarBox from '../assets/images/Dashboard-25.png'

import notification from '../assets/images/Icons-nav-notifications.svg'
import gift from '../assets/images/icon-wedding-gift.png'
import { getSubscription } from '../config/subscription'

import axios from 'axios'
import { Button, ButtonGroup } from '@mui/material';
import { GET_NOTIFICATION } from '../actions/types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom';



export const logOut = () => {
  return <Redirect to="/logout" />
}

const NotificationModal = props => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [image, setImage] = useState(avatar)
  const [nextBill, setNextBill] = useState('')
  const [tokenNA, setTokenNA] = useState(false)

  const [notifications, setNotifications] = useState([])
  const [newNotifications, setNewNotifications] = useState([])
  const [activeNotifications, setActiveNotifications] = useState([])
  const [activeAll, setActiveAll] = useState([true])
  const [allNoti, setAllNoti] = useState({})

  const [activePage, setActivePage] = useState(1)
  const [activeNewPage, setActiveNewPage] = useState(0)
  const [totalNewNotify, setTotalNewNotify] = useState(0)

  const first_name = localStorage.getItem('first_name')
  const last_name = localStorage.getItem('last_name')
  const role = localStorage.getItem('role')
  const apiToken = localStorage.getItem('access_token')

  const history = useHistory()

  // useEffect(() => {
  //   processActiveNotifications()
  // }, [activeAll, notifications])

  useEffect(() => {
    if (props.name === 'Profile') setImage(avatar)
    if (props.name === 'Gift') setImage(gift)
    if (props.name === 'Notification') setImage(notification)
  }, [image])

  useEffect(() => {

    // console.info("deployed")

    if (apiToken == null) {
      return <Redirect to="/logout" />
    }
    fetchNotifications(activePage)
    loadSubscription()



  }, [])

  const loadSubscription = async () => {
    const subs = await localStorage.getItem("subscription_nextbill")
    if (subs?.length) {
      setNextBill(subs)
      return
    }
    getSubscription()
      .then(async res => {
        const obj = {}
        const d = res.data.data

        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]

        obj.ends = d.current_term_end
        if (d.status == 'in_trial') {
          obj.ends = d.trial_end
        }

        let dt = obj.ends.split(' ')
        dt = dt[0]

        let dts = dt.split('-')
        dts[1] = parseInt(dts[1]) - 1

        dt = dts[2] + '  ' + months[dts[1]] + ' ' + dts[0]
        await localStorage.setItem("subscription_nextbill", dt)
        setNextBill(dt)
      })
      .catch(err => console.error())




  }

  const fetchNotifications = async page => {

    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/notification/in-app-notification?page=${page}`
    axios
      .get(url)
      .then(res => {


        let key = 'all'
        if (!activeAll) {
          key = 'unread'
        }
        setAllNoti({ ...res?.data.data })
        dispatch({
          type: GET_NOTIFICATION,
          payload: res.data
        })
        const result = res?.data.data[key].data.map(item => {
          // let lead = item.content.split(':')
          let content = item.content

          // if (lead[1]) {
          //   content = lead[0]
          //   lead = parseInt(lead[1].trim())
          // } else {
          //   lead = 0
          // }

          return {
            ...item,
            content: content,
            // lead: lead
          }
        })

        setTotalNewNotify(res?.data.data.unread.total)

        if (activeAll) {
          const allNotify = [...result]
          setNotifications(allNotify)
          setActiveNotifications(allNotify)
        } else {
          const allNotify = [...result]
          setNewNotifications(allNotify)
          setActiveNotifications(allNotify)
        }
      })
      .catch(err => {
        if (err?.response?.data?.errors?.length) {
        }
      })
  }

  const processActiveNotifications = () => {
    if (activeAll) {


      setActiveNotifications(notifications)
    } else {
      if (activeNewPage === 0) {
        setActiveNewPage(1)
        fetchNotifications(1)
      } else {
        //setActiveNotifications(newNotifications)
        syncNewNotifications(true)
      }

    }
  }

  const syncNewNotifications = active => {

    const newNotify = notifications?.filter(notify => {
      if (!notify.read) {
        return notify
      }
    })

    //console.info({newNotify, notifications})  

    setNewNotifications(newNotify)
    setTotalNewNotify(totalNewNotify - 1)
    if (active) {
      setActiveNotifications(newNotify)
    }

  }

  const seenNotification = async notify => {

    console.info({ notify })


    // goToAccount() 



    console.info({ notify })


    const allNotify = notifications.map(notification => {
      if (notify.id === notification.id) {
        return {
          ...notification,
          read: 1
        }
      }
      return notification
    })


    // goToAccount()

    // if (notify.read) {
    //   if (notify.lead) {
    //     history.push(`/calldashboard?callId=${notify.lead}`)
    //   }
    // }


    let id = notify.id

    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/notification/read`
    axios
      .post(url, { ids: [id] })
      .then(res => {
        if (res?.status === 200) {
          fetchNotifications(activePage)
        }
      })
      .catch(err => {
        if (err?.response?.data?.errors?.length) {

        }
        setNotifications(allNotify)
        // goToAccount() 

      })



  }

  const redirectTo = (notify) => {
    if (notify.content_type && notify.content_id) {
      if (notify.content_type === 'App\\Contact') {
        return `/contacts`
      } else if (notify.content_type === 'App\\Lead') {
        return `/leads/${notify.content_id}`
      }
      else if (notify.content_type === 'App\\Conversation') {
        return `/conversation`
      }
      return '';
    }
  }

  const removeNotification = id => {
    const allNotify = notifications?.filter((notification, index) => {
      if (id !== notification.id) {
        return notification
      }
    })
    setNotifications(allNotify)

    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/notification/delete/${id}`
    axios
      .post(url, {})
      .then(res => {

      })
      .catch(err => {
        if (err?.response?.data?.errors?.length) {

        }
      })
  }

  const checkNewNotifications = () => {
    const newNotify = notifications?.filter(item => {
      return parseInt(item.read) === 0
    })

    setNewNotifications(newNotify)
  }

  const close = () => setOpen(false)

  const onCloseModal = () => {
    const profile = document.querySelector('.hours-call-wrapper')
    if (profile) {
      profile.parentNode.parentNode.parentNode.click()
    }
  }

  const goToAccount = () => {
    setOpen(!open)
  }

  const handleModalOpen = () => {
    onCloseModal()
    setOpen(!open)
  }

  const timeSince = date => {
    var seconds = Math.floor((new Date() - date) / 1000)

    var interval = seconds / 31536000

    if (interval > 1) {
      return Math.floor(interval) + ' years'
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + ' months'
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + ' days'
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + ' hours'
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + ' minutes'
    }
    return Math.floor(seconds) + ' seconds'
  }

  const seenAllNotification = async () => {
    if (!newNotifications?.length) return

    const id = newNotifications.map(notify => notify.id)
    const allNotify = notifications.map(notify => {
      return { ...notify, read: 1 }
    })



    setNotifications(allNotify)
    setTotalNewNotify(0)
    setActiveAll(true)

    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/notification/read-all`
    axios
      .post(url, { ids: id })
      .then(res => {

      })
      .catch(err => {
        if (err?.response?.data?.errors?.length) {

        }
      })
  }

  const renderBellNotify = () => {
    return (
      <div className="position-relative">
        {allNoti?.unread?.total ? (
          <div
            className="count"
            style={{
              top: '-11px',
              color: '#fff',
              right: '0px',
              textAlign: 'center'
            }}
          >
            {allNoti?.unread?.total}
          </div>
        ) : (
          ''
        )}
        <i
          onClick={() => handleModalOpen(true)}
          data-tip="Notifications"
          className="fa fa-bell"
          aria-setsize="20"
          style={{
            color: 'white',
            marginLeft: '10px',
            fontSize: 21,
            marginRight: 10,
            verticalAlign: 'middle'
          }}
          aria-hidden="true"
        ></i>
      </div>
    )
  }

  const paginateNotification = () => {
    if (activeAll) {
      fetchNotifications(activePage + 1)
      setActivePage(activePage + 1)
    } else {
      fetchNotifications(activeNewPage + 1)
      setActiveNewPage(activeNewPage + 1)
    }
  }


  const handleNoti = (value) => {

    const result = allNoti[value].data.map(item => {
      // let lead = item.content.split(':')
      let content = item.content

      // if (lead[1]) {
      //   content = lead[0]
      //   lead = parseInt(lead[1].trim())
      // } else {
      //   lead = 0
      // }

      return {
        ...item,
        content: content,
        // lead: lead
      }
    })

    let allNotify = [...result]
    setNotifications(allNotify)
    setActiveNotifications(allNotify)

  }
  const profile = localStorage.getItem('profile_pic')

  return (
    <>
      <div className="mini-notification">
        <Modal
          className="mini-notification nav_mini_notify"
          size="mini"
          open={open}
          onClose={close}
          trigger={renderBellNotify()}
        >
          <Modal.Header>
            <div style={{ float: 'right', marginBottom: '10px' }}>

              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button variant="outlined" onClick={e =>
                  handleNoti("all")
                }

                >All</Button>
                <Button variant="outlined" onClick={e =>
                  handleNoti("today")
                }>Today</Button>
                <Button variant="outlined" onClick={e =>
                  handleNoti("unread")
                }>Unread</Button>
              </ButtonGroup>

            </div><div>
              <span className="header-badge">{totalNewNotify} New</span>
              {/* <span
              className="header-filter"
              onClick={e => {
                setActiveAll(!activeAll)
              }}
            >
              {' '}
              {activeAll ? 'Filter by Unread' : 'Show all'}{' '}
            </span> */}
            </div>
          </Modal.Header>
          <Modal.Content style={{ padding: '0px 0px !important' }}>
            <div className="notification-wrapper">
              {activeNotifications.map(notify => (
                <div
                  className={notify.read
                    ? 'notification-item '
                    : 'notification-item notification-item-new'}
                >
                  <div
                    onClick={() => {
                      // notify.read=false
                      seenNotification(notify);
                    }}
                    className="notification-avatar-holder"
                  >
                    <img
                      style={{
                        borderRadius: '100%',
                        width: '24px',
                        objectFit: 'cover'
                      }}
                      src={profile ? profile : image}
                      alt="img"
                    />
                  </div>
                  <div
                    onClick={() => {
                      seenNotification(notify);
                    }}
                    className="notification-content-holder"
                  >
                    <p className="notification-text"> {notify.content} - <Link to={redirectTo(notify)}>view</Link> </p>
                    <p className="notification-time">
                      {' '}
                      {timeSince(new Date(notify?.created_at + ' UTC'))}{' '}
                    </p>
                  </div>
                  <span
                    style={{ zIndex: 1000 }}
                    className="remove-notification"
                    onClick={() => {
                      removeNotification(notify.id);
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </span>
                </div>
              ))}

              {activeNotifications?.length === 0 ? (
                <div style={{ height: '100%', display: 'table' }}>
                  <span
                    style={{
                      display: 'table-cell',
                      verticalAlign: 'middle',
                      padding: '100px',
                      textAlign: 'center'
                    }}
                  >
                    <p>No new notification's</p>
                  </span>
                </div>
              ):''}
            </div>{' '}
          </Modal.Content>{' '}
          <Modal.Actions style={{ minHeight: '30px' }}>
            <div
              onClick={() => {
                seenAllNotification()
              }}
              className="footer-text"
            >
              <p>{totalNewNotify ? 'Mark all as read' : ''}</p>
            </div>

            <div
              onClick={() => {
                paginateNotification()
              }}
              className="footer-text"
            >
              <p>Load More</p>
            </div>
          </Modal.Actions>
        </Modal>{' '}
        <ReactTooltip />
      </div>
    </>
  )
}

export default NotificationModal
